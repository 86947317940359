import { Typography } from 'antd';
import classNames from 'classnames';

require('./styles.less');

const { Title } = Typography;

const PageContainer = (props) => {
  const { children, className, title = '' } = props;

  return (
    <div className={classNames('page', className)}>
      <div className="page__wrapper">
        <PageTitle title={title} />
        {children}
      </div>
    </div>
  );
};

const PageTitle = ({ addTopMargin, title }) => {
  if (!title) {
    return null;
  }

  return (
    <Title className={classNames('page__title', { 'page__title_margin-top': addTopMargin })} level={1}>
      {title}
    </Title>
  );
};

PageContainer.Title = PageTitle;

export default PageContainer;
