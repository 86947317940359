import classNames from 'classnames';
import { convertFromRaw, Editor, EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import { useState } from 'react';

require('./styles.less');

const EMPTY_LINE = '<p><br></p>';

const emptyContentState = convertFromRaw({
  entityMap: {},
  blocks: [
    {
      text: '',
      key: 'foo',
      type: 'unstyled',
      entityRanges: []
    }
  ]
});

// Dirty hack to fix selection issue in Safari
// TODO: Remove me someday
if (typeof Selection !== 'undefined') {
  const nativeSelectionExtend = Selection.prototype.extend;

  Selection.prototype.extend = (...args) => {
    try {
      return nativeSelectionExtend.apply(this, args);
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };
}

const removeEndingEmptyLine = (input) => {
  let str = input.replace(/\r?\n|\r/g, '');

  if (str.endsWith(EMPTY_LINE)) {
    const splits = str.split(EMPTY_LINE);

    do {
      splits.pop();
    } while (splits.length && !splits[splits.length - 1]);

    str = splits.join(EMPTY_LINE);
  }

  return str;
};

const RichTextEditorFormItemComponent = (props) => {
  const { className, onChange, placeholder, value } = props;
  const [editorState, setEditorState] = useState(() =>
    value ? EditorState.createWithContent(stateFromHTML(value)) : EditorState.createWithContent(emptyContentState)
  );

  const handleChange = (state) => setEditorState(state);

  const handleBlur = () => {
    const html = removeEndingEmptyLine(stateToHTML(editorState.getCurrentContent()));

    onChange(html);
  };

  return (
    <div className={classNames('rich-text-editor', className)}>
      <Editor editorState={editorState} onBlur={handleBlur} onChange={handleChange} placeholder={placeholder} />
    </div>
  );
};

export default RichTextEditorFormItemComponent;
