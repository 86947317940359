import jwtDecode from 'jwt-decode';
import { createContext, useState } from 'react';

import AuthToken from './authToken';

const userContext = createContext(null);
const { Provider } = userContext;

const ROLE = {
  ADMIN: 'admin',
  COMPANY: 'company',
  INDIVIDUAL: 'individual',
  PERSON: 'person'
};

export const getUserFromToken = () => {
  const authToken = AuthToken.getToken();
  let decodedToken;

  try {
    decodedToken = jwtDecode(authToken);
  } catch {
    return null;
  }

  const { avatar, email, flags = {}, name, role, user_id: id } = decodedToken?.sub;
  const isAdmin = role === ROLE.ADMIN;

  return {
    avatar,
    canResponse: Boolean(flags.candidatable),
    id,
    isAdmin,
    isVerified: Boolean(flags.verified),
    email,
    name,
    role,
    showCandidateGuide: !flags.candidatable && role !== ROLE.PERSON && !isAdmin
  };
};

export const UserProvider = (props) => {
  const { children } = props;
  const [currentUser, setUser] = useState(getUserFromToken());
  userContext.setUser = setUser;

  return <Provider value={{ currentUser, setUser }}>{children}</Provider>;
};

export default userContext;
