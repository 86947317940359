import { Layout } from 'antd';

const EmptyContentComponent = () => {
  return (
    <Layout>
      <Layout.Content />
    </Layout>
  );
};

export default EmptyContentComponent;
