import Link from 'next/link';
import { cloneElement, forwardRef } from 'react';

// This component is used to get Link functionality without <a> tag and without errors in console
// eslint-disable-next-line no-unused-vars
const OverrodeChildren = forwardRef((props, ref) => {
  const { children, href, ...otherProps } = props;

  return <>{cloneElement(children, { ...otherProps })}</>;
});

const ComponentLinkComponent = (props) => {
  const { children, target, ...otherProps } = props;

  if (!otherProps.as) {
    otherProps.as = otherProps.href;
  }

  if (target) {
    return <OverrodeChildren onClick={() => window.open(otherProps.as, target)}>{children}</OverrodeChildren>;
  }

  return (
    <Link {...otherProps} passHref>
      <OverrodeChildren>{children}</OverrodeChildren>
    </Link>
  );
};

export default ComponentLinkComponent;
