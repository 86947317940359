import store from 'store2';

const TOKEN_KEY = 'auth_token';

const AuthToken = {
  getToken: () => {
    return store.session(TOKEN_KEY) || store(TOKEN_KEY);
  },
  saveToken: (token, sessionOnly) => {
    if (sessionOnly) {
      store.session(TOKEN_KEY, token);

      return;
    }

    store(TOKEN_KEY, token);
  },
  updateToken: (token) => {
    const sessionOnly = store.session.has(TOKEN_KEY);

    AuthToken.saveToken(token, sessionOnly);
  },
  removeToken: () => {
    store.session.remove(TOKEN_KEY);
    store.remove(TOKEN_KEY);
  }
};

export default AuthToken;
