const HIDDEN_CHAR = '⁣';

export const MASK_CHAR = '░';

export const GUEST_USER = 'guest';

export const GLOBAL_FORM_TYPE = {
  REMIND_PASSWORD: 'remindPassword',
  SIGN_IN: 'signIn',
  SIGN_UP: 'signUp'
};

export const MASK = {
  PHONE: '{+7} (000) 000-00-00',
  PHONE_WITH_MASK_CHAR: {
    mask: '{+7} (###) ###-##-##',
    maskProps: {
      definitions: {
        '#': new RegExp(`[0-9${MASK_CHAR}]`)
      }
    }
  }
};

export const SOCIAL_NETWORK = {
  vk: {
    id: 'vk',
    title: 'ВКонтакте',
    placeholder: 'https://vk.com/id12345',
    mask: '{https://vk.com/}#####[###########################]',
    maskProps: {
      definitions: {
        '#': /[A-Za-z0-9._]/
      },
      placeholderChar: HIDDEN_CHAR
    }
  },
  // fb: {
  //   id: 'fb',
  //   title: 'Facebook',
  //   placeholder: 'https://www.facebook.com/my.name',
  //   mask: '{https://www.f\\acebook.com/}#####[#############################################]',
  //   maskProps: {
  //     definitions: {
  //       '#': /[A-Za-z0-9.?=]/
  //     },
  //     placeholderChar: HIDDEN_CHAR
  //   }
  // },
  telegram: {
    id: 'telegram',
    title: 'Telegram',
    placeholder: '@nickname',
    mask: '{@}#####[#########################]',
    maskProps: {
      definitions: {
        '#': /[A-Za-z0-9_]/
      },
      placeholderChar: HIDDEN_CHAR
    },
    transformLink: (data) => data.replace('@', 'https://t.me/')
  },
  whatsapp: {
    id: 'whatsapp',
    title: 'WhatsApp',
    placeholder: '+7 (123) 456-78-90',
    mask: MASK.PHONE,
    transformLink: (data) => data.replace('+', 'https://wa.me/')
  },
  // instagram: {
  //   id: 'instagram',
  //   title: 'Instagram',
  //   placeholder: '@nickname',
  //   mask: '{@}##[############################]',
  //   maskProps: {
  //     definitions: {
  //       '#': /[A-Za-z0-9._]/
  //     },
  //     placeholderChar: HIDDEN_CHAR
  //   },
  //   transformLink: (data) => data.replace('@', 'https://www.instagram.com/')
  // },
  skype: {
    id: 'skype',
    title: 'Skype',
    placeholder: 'nickname',
    mask: '######[##########################]',
    maskProps: {
      definitions: {
        '#': /[A-Za-z0-9.,_-]/
      },
      placeholderChar: HIDDEN_CHAR
    },
    transformLink: (data) => `skype:${data}?chat`
  }
};

export const SORT_TYPE = {
  BUDGET_ASC: 'ba',
  BUDGET_DESC: 'bd',
  CREATED_DATE_DESC: 'cdd',
  EXPECTED_DATE_ASC: 'eda',
  EXPECTED_DATE_DESC: 'edd'
};
