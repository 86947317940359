import { Rate, Tooltip } from 'antd';

const UserRatingComponent = (props) => {
  const { className, value } = props;

  if (!value) {
    return (
      <div className={className}>
        <Rate disabled value={0} />
      </div>
    );
  }

  return (
    <Tooltip title={`Рейтинг: ${Math.round((value + Number.EPSILON) * 100) / 100}`} placement="right">
      <div className={className}>
        <Rate disabled value={value} allowHalf />
      </div>
    </Tooltip>
  );
};

export default UserRatingComponent;
