import classNames from 'classnames';

require('./styles.less');

const WarningComponent = (props) => {
  const { inline, text, title } = props;

  return (
    <div className={classNames('warning', { 'warning_without-title': !title, warning_inline: inline })}>
      {title && <div className="warning__title">{title}</div>}
      <div className="warning__text">{text}</div>
    </div>
  );
};

export default WarningComponent;
