import parseHtml from 'html-react-parser';
import { isEmpty, isEqual, isObject, snakeCase, transform } from 'lodash';
import moment from 'moment';
import { parse, serialize } from 'uri-js';

export const deeplyApplyKeyTransform = (obj, transformMethod) => {
  const data = Array.isArray(obj) ? [] : {};

  Object.keys(obj).forEach((key) => {
    if (obj[key] != null && typeof obj[key] === 'object') {
      data[transformMethod(key)] = deeplyApplyKeyTransform(obj[key], transformMethod);
    } else {
      data[transformMethod(key)] = obj[key];
    }
  });

  return data;
};

export const mapFilesToUploadedFiles = (files) =>
  (files ?? []).map(({ id, name, ...otherProps }, index) => ({
    uid: id,
    id,
    index,
    name,
    status: 'done',
    otherProps,
    url: otherProps.preview || otherProps.original
  }));

export const mapFilesToGalleryImages = (files, modifiedByUploader) => {
  if (modifiedByUploader) {
    return (files ?? []).map(({ otherProps: { big, original, preview } = {}, thumbUrl }) => ({
      original: thumbUrl || big || original,
      thumbnail: thumbUrl || preview || original
    }));
  }

  return (files ?? []).map(({ big, original, preview, thumbUrl }) => ({
    original: thumbUrl || big || original,
    thumbnail: thumbUrl || preview || original
  }));
};

export const mapUploadedFilesToFiles = (uploadedFiles) => (uploadedFiles ?? []).map(({ id }) => id);

export const formatPrice = (value) => (value ? `${value.toLocaleString()} ₽` : '');

export const formatDate = (value) => (value ? moment(value).format('L') : '');

export const formatDeadline = (value) => (value ? moment(value).format('HH:mm DD.MM.YYYY') : '');

export const useIdAsUrlSlug = (searchParams, url) => {
  if (searchParams) {
    const { id, ...params } = searchParams;

    const paramsKeys = Object.keys(params);
    const idParam = id ? `/${id}` : '';
    const encodedParams = paramsKeys.length
      ? `/?${paramsKeys
          .map((key) => `${encodeURIComponent(snakeCase(key))}=${encodeURIComponent(params[key])}`)
          .join('&')}`
      : '';

    return `${url}${idParam}${encodedParams}`;
  }

  return url;
};

export const deepDiff = (changed, original) => {
  const originalClone = {
    ...original
  };

  // We send to the backend an array with file ids, but the original response has array of objects with extra info about each file
  if (originalClone.files) {
    originalClone.files = originalClone.files.map(({ id }) => id);
  }

  const changes = (a1, a2) => {
    return transform(a1, (result, value, key) => {
      if (!isEqual(value, a2[key])) {
        result[key] = isObject(value) && isObject(a2[key]) ? changes(value, a2[key]) : value;
      }
    });
  };

  const diff = changes(changed, originalClone);

  return isEmpty(diff) ? null : diff;
};

export const getUserId = ({ router, user }) => {
  let userId;

  if (router?.query?.userId) {
    userId = router?.query?.userId;
  }

  if (user?.id) {
    userId = user.id;
  }

  return Number(userId ?? 0);
};

export const encodeUri = (uri) => serialize(parse(uri));

export const decodeUri = (uri) => serialize(parse(uri), { iri: true });

export const parseHTML = (html) => parseHtml(html ?? '');
